import React from 'react'
import { Container } from 'reactstrap'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Link from 'gatsby-link'
import { FaArrowLeft } from 'react-icons/fa';

export default function Template ({ data }) {
  const sant = 'Συσκευασμένα κρύα σάντουιτς';
  const { markdownRemark: post } = data
  return (
    <Layout>
      <div>
        <Helmet title={`${sant} | ${data.site.siteMetadata.title}`} />
        <Container className="sandwich-content">
          <h1 className='display-3'>{post.frontmatter.title}</h1>
        </Container>
        <img src={post.frontmatter.thumbnail} alt={post.frontmatter.title} className="product-image"/>
        <Container dangerouslySetInnerHTML={{ __html: post.html }} className="col-lg-6 float-right systatika"/>
        <div class="container row benefitsContainer">
        <div className="col-lg-6">
          <h4>Συσκευασμένα κρύα σάντουιτς Σπιτικό Παπακωνσταντίνου:</h4>
          <ul class="benefits">
            <li>ενέργεια</li>
            <li>φυτικές ίνες</li>
            <li>χωρίς συντηρητικά</li>
          </ul>
        <p><b>Πιστοποιημένα με ISO 22000.</b></p>

        </div>
        <div className="col-lg-6">

          </div>
          </div>
      </div>
      <Link to="/συσκευασμενα-κρυα-σαντουιτς" className="back-button"><FaArrowLeft className="back-icon"/> Συσκευασμένα κρύα σάντουιτς</Link>
    </Layout>
  )
}

export const sandwichesPageQuery = graphql`
  query sandwichesPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html 
      frontmatter {
        path
        title
        thumbnail
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
